import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-scroll';

const Privacy = () => {
    return (
        <div>
            <h1>
                MOje podmínky použití.
            </h1>
        </div>
    );
};
 
export default Privacy;